<template>
  <div>
    <!-- Start card wrapper -->
    <v-card class="SearchWrapper">
      <v-layout width="100%" row :algin-start="mdUp" :align-center="!mdUp">
        <!-- Start search title -->
        <v-flex xs12 sm6 style="margin-right: 1%">
          <p class="FieldLabel"></p>
          <!-- End search title -->
          <!-- Start search text field -->
          <v-text-field
            class="custom-placeholder"
            outlined
            dense
            background-color="#FAFAFA"
            clearable
            v-model="sSearch"
            append-icon="mdi-magnify"
            :placeholder="'Búsqueda general...'"
          >
          </v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm2 class="text-right">
          <p class="FieldLabel"></p>
          <v-btn
            @click="setAddUser"
            color="primary"
            style="
              color: black;
              box-shadow: 0px 20px 40px #00000029;
              border-radius: 10px;
              font-weight: 600;
            "
            >Nuevo usuario
          </v-btn>
        </v-flex>
        <!-- End search text field -->
      </v-layout>
    </v-card>

    <v-dialog
      persistent
      class="dialog-users"
      v-model="dialog"
      width="700"
      scrollable
      style="z-index: 9999"
    >
      <v-card
        style="border-radius: 10px; padding-top: 15px; padding-bottom: 15px"
      >
        <v-icon
          @click="dialog = false"
          color="#000000"
          style="position: absolute; right: 23px"
          >mdi-close
        </v-icon>
        <v-card-title
          class="headline justify-center"
          style="text-align: center"
        >
          Nuevo usuario
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="nombreUsuario"
                  class="text-Poppins"
                  label="Nombre*"
                  style="margin-bottom: -10px"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="correoUsuario"
                  class="text-Poppins"
                  label="Correo electrónico*"
                  style="margin-bottom: -10px"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <!-- <v-text-field
                  v-model="puestoUsuario"
                  class="text-Poppins"
                  label="Puesto*"
                  style="margin-bottom: -10px"
                ></v-text-field> -->
                <v-select
                  v-model="puestoUsuario"
                  item-text="sName"
                  item-value="sId"
                  :items="itemsPuesto"
                  class="custom-placeholder"
                  label="Puesto*"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="telefonoUsuario"
                  class="text-Poppins"
                  label="Teléfono"
                  type="number"
                  style="margin-bottom: -10px"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="contrasenaUsuario"
                  class="text-Poppins"
                  label="Contraseña*"
                  autocomplete="new-password"
                  style="margin-bottom: -10px"
                  :append-icon="bShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (bShowPassword = !bShowPassword)"
                  :type="bShowPassword ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="confirmarContrasenaUsuario"
                  class="text-Poppins"
                  label="Confirmar contraseña*"
                  style="margin-bottom: -10px"
                  :append-icon="
                    bShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  @click:append="
                    () => (bShowConfirmPassword = !bShowConfirmPassword)
                  "
                  :type="bShowConfirmPassword ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
              <v-col
                v-for="(item, index) in rolesUsuario"
                :key="index"
                cols="12"
                sm="6"
                md="6"
              >
                <v-checkbox
                  v-model="aPermissions"
                  :label="diccionarioCheckboxs[item.sName]"
                  :value="item.sRoleId"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-layout justify-center style="margin-top: 25px">
          <v-card-actions justify-center>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6" pa-3>
                  <v-btn
                    @click="dialog = false"
                    x-large
                    color="black"
                    width="175"
                    outlined
                    style="
                      height: 40px;
                      font-size: 13px;
                      font-weight: 600;
                      border-radius: 5px;
                    "
                    >Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="6" pa-3>
                  <v-btn
                    x-large
                    width="175"
                    color="primary"
                    style="
                      height: 40px;
                      color: black;
                      box-shadow: 0px 20px 40px #00000029;
                      border-radius: 5px;
                      font-size: 13px;
                      font-weight: 600;
                    "
                    :disabled="!validateForm"
                    @click="createUser"
                    :loading="loadingButtonCrear"
                    >Crear
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- End card wrapper -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      itemsPuesto: [
        {
          sName: "Administrativo",
          sId: "1",
        },
        {
          sName: "Residente",
          sId: "2",
        },
        {
          sName: "Director",
          sId: "3",
        },
      ],
      rolesUsuario: [],
      aPermissions: [],
      diccionarioCheckboxs: {
        Administrator: "Administrador",
        PurchaseOrder: "Alta de órdenes de compra",
        ManagementPurchaseOrders: "Gestion de órdenes de compra",
        Supplier: "Alta de proveedores",
        //Projects: "Creación de proyectos inmobiliarios",
        Projects: "Creación de proyectos",
        RequestForMaterials: "Solicitud de material",
        PurchaseUser: "Alta de usuarios",
        ExpenseManagement: "Gestión de gastos",
      },
      bShowPassword: false,
      bShowConfirmPassword: false,
      dialog: false,
      nombreUsuario: "",
      correoUsuario: "",
      puestoUsuario: "",
      telefonoUsuario: "",
      contrasenaUsuario: "",
      confirmarContrasenaUsuario: "",
      proyectoUsuario: "",
      solicitudMaterialUsuario: "",
      ordenCompraUsuario: "",
      gestionOrdenCompraUsuario: "",
      proveedorUsuario: "",
      usuarioUsuario: "",
      sSearch: "",
      loadingButtonCrear: false,
    };
  },
  beforeMount() {
    this.$store.commit("setSearch", "");
  },
  computed: {
    validateForm() {
      return (
        this.nombreUsuario !== "" &&
        this.correoUsuario !== "" &&
        this.puestoUsuario !== "" &&
        this.contrasenaUsuario !== "" &&
        this.confirmarContrasenaUsuario !== "" &&
        this.aPermissions.length > 0
      );
    },
  },
  mounted() {
    this.getPermissions();
  },
  methods: {
    createUser() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regex.test(String(this.correoUsuario).toLowerCase())) {
        if (
          this.contrasenaUsuario == this.confirmarContrasenaUsuario ||
          this.confirmarContrasenaUsuario == this.contrasenaUsuario
        ) {
          this.sendUser();
        } else {
          const alert = {
            color: "red",
            text: "Las contraseñas deben de ser iguales.",
          };
          this.$store.commit("toggle_alert", alert);
        }
      } else {
        const alert = {
          color: "red",
          text: "El formato del correo electrónico es incorrecto.",
        };
        this.$store.commit("toggle_alert", alert);
      }
    },
    sendUser() {
      this.loadingButtonCrear = true;

      const payload = {
        sFullName: this.nombreUsuario,
        sEmail: this.correoUsuario,
        sPassword: this.confirmarContrasenaUsuario,
        sPosition: this.puestoUsuario,
        sPhoneNumber: this.telefonoUsuario,
        aPermissions: this.aPermissions,
      };

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          contentType: "application/x-www-form-urlencoded;charset=utf-8",
        },
      };
      db.post(`${uri}/api/v1/users`, payload, config)
        .then((response) => {
          this.dialog = false;
          this.loadingButtonCrear = false;
          this.nombreUsuario = "";
          this.correoUsuario = "";
          this.contrasenaUsuario = "";
          this.confirmarContrasenaUsuario = "";
          this.puestoUsuario = "";
          this.telefonoUsuario = "";
          this.aPermissions = [];
          const alert = {
            color: "green",
            text: response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingButtonCrear = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getPermissions() {
      db.get(`${uri}/api/v1/roles/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.rolesUsuario = resp.data.roles.filter((e) => {
            if (!e.sName.includes("Administrator")) {
              return e;
            }
          });
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    setAddUser() {
      this.$router.push({ name: "AddOrEditUser", params: { sIdUser: "0" } });
      // this.$router.push({
      //   name: "SupplierDetailOC",
      //   params: { sOCId: sOCId },
      // });
    },
  },
  watch: {
    // set state to local search
    sSearch: lodash.debounce(function(val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 800),
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
};
</script>
<style>
.FieldLabel {
  text-align: left;
  font: normal normal normal 12px/14px "Raleway", sans-serif;
  margin-bottom: 0px !important;
  margin-left: 20px;
  z-index: 100;
  color: #008b83;
  opacity: 1;
}
.custom-placeholder input::placeholder {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}
.custom-placeholder input::-moz-placeholder {
  text-align: left;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}
</style>
<style scoped>
.v-dialog__content--active .v-input {
  margin-bottom: -25px;
}

.v-dialog__content--active .v-input--checkbox {
  margin-bottom: -55px;
}

.v-dialog__content--active .v-card__text {
  overflow-y: hidden;
}

.searchTextField {
  margin-top: -28px;
}
.SearchWrapper {
  max-height: 80px;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px transparent !important;
  border-radius: 30px !important;
  width: 100%;
}
</style>
